.shopping_cart{
  margin-top: 15%;
}


.userodernav1{
    margin-top: 5%;
  }
  .userordercard1{
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .userordercardheader1{
    margin-top:0%;
    margin-bottom: 0%;
  }
  .userorderpageimg1{
    margin-top: 10px;
    width:100px ;
    height:120px;
  }
  .usercardbtn1{
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 350px) {
    .shopping_cart{
      margin-top: 25%;
    }
    
  }
  /* .gradient-custom {
    
    background: #6a11cb;
    
    
    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
    
   
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
    } */

    .trash .fa-trash{
      margin-left: 10px;
      margin-right: 10px;
    
    }

    .trash .fa-heart{
      margin-left: 10px;
      margin-right: 10px;
    }

    .btn-dangers{
    background-color: #dc4c64 !important;
    color: #fff;
    box-shadow: 0 4px 9px -4px #d1485f;
   
}
    .btn-dangers:hover{
      background-color: #d1485f !important;
    }

    .ahover.button-cart{
      color: #fff;
      box-shadow: 0 4px 9px -4px #ffcc00;
    }
    .ahover a:hover { 
      text-decoration: none !important;
    } 