
 .invoice-head{
	display: flex;
	align-items: center;
	justify-content: center;
 }
 .bill-details{
	/* margin-top: 40px; */
 }

 .billing-address-details{
	margin-top: 40px;
	padding: 10px 10px 10px 10px;
	border: 2px solid black;
	height: 90%;
 }

 .shipping-address-details{
	margin-top: 40px;
	padding: 10px 10px 10px 10px;
	border: 2px solid black;
	height: 90%;

 }
 .customer-details{
    margin-top: 60px;
 }
 .customer-details-head{
	margin-top: 20px;
 }
 .bill-customer{
	margin: 5px 5px 5px 5px;
	padding: 5px 5px 5px 5px;
	border: 2px solid black;
 }
 .invoice{
	background-image: url(../../public/assets/img/watermark.jpeg);
	background-repeat: no-repeat;
	/* background-size: 100% 70% */
}

.main_image {
    background-image: url(../../public/assets/img/templet.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 800px;
    height: 1120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.qr-image {
    height: 300px;
    width: 300px;
	margin-left: 30px;
}
.reg_id{
	margin-top: 500px;
	font-size: 40px;
}
.details{
	margin-top: 60px;
	margin-left: -60px;
	font-size: 30px;
}

.Qrimg {
	display: block;
	width: 480px;
	height: 480px;
	margin: 2rem auto;
}

.QRbutton a {
	appearance: none;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;

	color: #2bcb4b;
	font-size: 1.5rem;
	text-decoration: none;
}


@media (max-width: 992px) {
    .cardbody {
        max-height: 100vh;
    }
}

@media (max-width: 770px) {
    .shipping-address-head
    {
        margin-top: 30px;
    }
}


.invoice-table {
	/* width: 80%; */
    /* margin: 20px; */
    border-radius: 8px;
    border: 1px solid #ccc;
    overflow: hidden;
    /* background-color: #f9f9f9; */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.invoice-table-head {
    /* background-color: #007bff; */
    color: #161515;
    padding: 15px;
    font-size: 1.2em;
    text-align: left;
}
button{
    white-space: nowrap !important;
    width: auto !important;
}

.invoice-table-body table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.invoice-table-body th, .invoice-table-body td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 0.9em;
}

.invoice-table-body th {
    background-color: #007bff;
    color: #0f0e0e;
    font-weight: 600;
    text-align: center;
    font-size: 0.95em;
}

.orders-table .report-margin th {
    font-size: 0.8em;
}

.orders-table .report-margin th[colspan="2"] {
    text-align: center;
}

.orders-table .report-margin td[colspan="10"] {
    background-color: #f1f1f1;
    font-weight: bold;
    text-align: center;
}

.invoice-table-body .total-row td {
    background-color: #f9f9f9;
    font-weight: bold;
    color: #333;
}

.invoice-table-body .total-row td b {
    font-size: 1em;
}

.orders-table td {
    vertical-align: middle;
    padding: 5px;
    text-align: center;
}

.orders-table .number-cell {
    width: 4%;
}

.orders-table .description-cell {
    width: 20%;
}

.orders-table .amount-cell {
    width: 10%;
}

.gst-image{
    height: 300px;
    width:300px;
}

@media (max-width: 767px) {
    .btn-sm-sm {
      padding: 0.25rem 0.5rem;
      width:45% !important;
    }
  }
  