

.sidebar_filter
{
	float: left;
	width: 200px;
	padding-right: 17px;
}
.sidebar_section
{
	margin-top: 30px;
	padding-bottom: 35px;
	margin-bottom: 50px;
	border-bottom: solid 1px #ebebeb;
}
.sidebar_title h5
{
	font-size: 18px;
	margin-bottom: 21px;
}
.sidebar_categories li.active a
{
	color: #fe4c50;
}
.sidebar_categories li.active:hover a
{
	color: #fe4c50;
}
.sidebar_categories li a
{
	font-size: 14px;
	line-height: 40px;
	color: #51545f;
}
.sidebar_categories li a:hover
{
	color: #b5aec4;
}
.sidebar_categories li a span
{
	margin-right: 5px;
}
#slider-range
{
	margin-left: 7px !important;
}
#amount
{
	margin-top: 8px;
	color: #1e1e27 !important;
	font-size: 16px !important;
	font-weight: 500 !important;
}
.filter_button
{
	width: 100%;
	height: 30px;
	background: #1e1e27;
	text-align: center;
	margin-top: 25px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.filter_button:hover
{
	background: #34343f;
}
.filter_button span
{
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 30px;
	color: #FFFFFF;
}
.checkboxes li
{
	height: 40px;
	line-height: 40px;
}
.checkboxes li.active i
{
	color: #fe4c50;
	margin-right: 21px;
}
.checkboxes li.active i::after
{
	display: block;
	position: absolute;
	top: 1px;
	left: 1px;
	font-family: 'FontAwesome';
	font-size: 10px;
	content: '\f00c';
	color: #FFFFFF;
}
.checkboxes
{
	height: 200px;
	overflow: hidden;
}
.checkboxes.active
{
	height: auto;
}
.checkboxes li i
{
	position: relative;
	color: #b3b7c8;
	cursor: pointer;
	margin-right: 22px;
}
.checkboxes li span
{
	font-family: 'Poppins', sans-serif;
	color: #51545f;
}
.show_more
{
	display: inline-block;
	cursor: pointer;
}
.show_more span
{
	height: 40px;
	line-height: 40px;
	color: #fe4c50;
}
.show_more span:first-child
{
	margin-right: 11px;
}


.main_content
{
	float: left;
	width: calc(100% - 200px);
	padding-left: 34px;
}


.products_iso
{
	width: 100%;
}
.section_title
{
	display: inline-block;
	text-align: center;
}
.section_title h2
{
	display: inline-block;
}
.section_title::after
{
	display: block;
	position: absolute;
	top: calc(100% + 13px);
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 60px;
	height: 5px;
	background: #fe4c50;
	content: '';
}

/*********************************
4.1 Product Sorting
*********************************/

.product_sorting_container_bottom
{
	margin-top: 24px;
}
.product_sorting_container_bottom .product_sorting
{
	float: right;
}
.product_sorting
{
	display: inline-block;
}
.product_sorting > li
{
	display: inline-block;
	position: relative;
	width: 185px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	border: solid 1px #ecedee;
	padding-left: 20px;
	padding-right: 7px;
	margin-right: 16px;
}
.product_sorting_container_bottom .product_sorting > li
{
	width: 144px;
	margin-right: 0px;
	padding-right: 12px;
}
.product_sorting_container_bottom .product_sorting li span:nth-child(2)
{
	margin-left: 24px;
}
.product_sorting li i
{
	float: right;
	line-height: 40px;
	color: #51545f;
}
.product_sorting li ul
{
	display: block;
	position: absolute;
	right: 0;
	top: 120%;
	margin: 0;
	width: 100%;
	background: #FFFFFF;
	visibility: hidden;
	opacity: 0;
	z-index: 1;
	box-shadow: 0 15px 25px rgba(63, 78, 100, 0.15);
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: all 0.3s ease;
}
.product_sorting > li:hover ul
{
	visibility: visible;
	opacity: 1;
	top: calc(100% + 1px);
}
.product_sorting li ul li
{
	display: block;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}
.product_sorting li ul li span
{
	display: block;
	height: 40px;
	line-height: 40px;
	border-bottom: solid 1px #dddddd;
	color: #51545f;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: all 0.3s ease;
}
.product_sorting li ul li span:hover
{
	color: #b5aec4;
}
.product_sorting li ul li:last-child span
{
	border-bottom: none;
}
.product_sorting li span:nth-child(2)
{
	margin-left: 71px;
}
.pages
{
	display: inline-block;
	float: right;
}
.product_sorting_container_bottom .pages
{
	float: left;
}
.page_current
{
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	text-align: center;
	border: solid 1px #ecedee;
	margin-right: 16px;
}
.product_sorting_container_bottom .pages .page_current
{
	background: #fe4c50;
	border: none;
}
.page_current span
{
	color: #51545f;
}
.product_sorting_container_bottom .pages .page_current span
{
	color: #FFFFFF;
}
.page_selection
{
	display: block;
	position: absolute;
	right: 0;
	top: 120%;
	margin: 0;
	width: 100%;
	background: #FFFFFF;
	visibility: hidden;
	opacity: 0;
	z-index: 1;
	box-shadow: 0 15px 25px rgba(63, 78, 100, 0.15);
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: all 0.3s ease;
}
.page_selection li
{
	display: block;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}
.page_selection li a
{
	display: block;
	height: 40px;
	line-height: 40px;
	border-bottom: solid 1px #dddddd;
	color: #51545f;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: all 0.3s ease;
}
.page_selection li a:hover
{
	color: #b5aec4;
}
.page_current:hover .page_selection
{
	visibility: visible;
	opacity: 1;
	top: calc(100% + 1px);
}
.page_total
{
	display: inline-block;
	line-height: 40px;
	margin-right: 31px;
}
.page_total span
{
	margin-right: 14px;
}
.page_next
{
	display: inline-block;
	line-height: 40px;
	cursor: pointer;
}
.page_next:hover i
{
	color: #b5aec4;
}
.page_next i
{
	font-size: 18px;
	color: #51545f;
}
.showing_results
{
	font-size: 16px;
	height: 40px;
	line-height: 40px;
	float: right;
	margin-right: 34px;
}

@media only screen and (max-width: 1300px)
{
	.product-item
	{
		height: 360px;
	}
	.product
	{
		height: 320px;
	}
}
@media only screen and (max-width: 991px)
{
	.product-item
	{
		width: 33.33333333%;
	}
	.main_content
	{
		float: none;
		width: 100%;
		padding-left: 0px;
	}
}

@media only screen and (max-width: 575px)
{
	.product-item
	{
		width: 50%;
		height: 420px;
	}
	.product
	{
		height: 380px;
	}
}

@media only screen and (max-width: 479px)
{

	.red_button a
	{
		font-size: 10px;
	}
	.product-item
	{
		width: 100%;
		height: auto;
	}
	.product
	{
		height: auto;
	}
	.product_name a
	{
		font-size: 12px;
	}
	.add_to_cart_button
	{
		margin-top: 20px;
	}
}


@media only screen and (max-width: 767px)
{
	h1{font-size: 36px;}
	h2{font-size: 24px;}
	h6{font-size: 12px;}
	.red_button a
	{
		font-size: 12px;
	}
	.new_arrivals_title
	{
		margin-top: 44px;
	}
	.product_sorting
	{
		display: block;
	}
	.product_sorting > li
	{
		display: block;
		margin-bottom: 15px;
	}
	.pages
	{
		float: none;
	}
	.showing_results
	{
		display: block;
		float: none;
		margin-bottom: 15px;
	}
	.product_sorting_container_bottom .product_sorting
	{
		float: none;
	}
	.product_sorting_container_bottom .pages
	{
		float: none;
	}
	.product-item
	{
		width: 33.333333333333%;
		height: 345px;
	}
	.product
	{
		height: 305px;
	}
}


@media only screen and (max-width: 480px)
{
	
}


.pagination {
    text-align: center;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
	margin-bottom: 40px;
}

.pages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 200px;
}

.page-number {
    cursor: pointer;
    background-color: white;
    color: #999;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease;
}

.activePage {
    font-size: 1.3em;
    height: 40px;
    width: 40px;
    background-color: #0057b3;
    color: white;
}

button {
    width: 120px;
    padding: 8px 16px;
    background-color: #ffffff00;
    color: #0057b3;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button:hover {
    color: #0056b3;
}

button:disabled {
    background-color: #ffffff00;
    color: #ccc;
    cursor: not-allowed;
}

#prevPage {
    margin-right: 20px;
}

#nextPage {
    margin-left: 20px;
}

.arrow {
    font-size: 1.2em;
}