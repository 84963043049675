/* body {
  
    overflow-x: hidden; 
  } */

  @import '~@ag-grid-community/styles/ag-grid.css';
@import '~@ag-grid-community/styles/ag-theme-quartz.css';
.orders-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .orders-table th {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .status-new {
    background-color: #e6ffe6;
    border-radius: 20px;
    color: #006600;
    font-weight: bold;
    padding: 4px 8px;
  }
  
  .status-processing {
    background-color: #e6e6ff;
    border-radius: 20px;
    color: #000099;
    font-weight: bold;
    padding: 4px 8px;
  }
  
  .status-shipped {
    background-color: #ffffcc;
    border-radius: 20px;
    color: #666600;
    font-weight: bold;
    padding: 4px 8px;
  }
  
  .report-margin{
    margin-top: 3%;
  }
  /* @media screen and (max-width: 1500px) {
    .report-margin{
        margin-top: 15%;
    }
} */

.csvDownload{
  text-decoration: none !important;
  float: right !important;
  margin-right: 40px;
  background-color: #ffcc00;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  color: black;
  margin-bottom: 20px;
}



.table-container {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
}

/* Style for the table */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

thead th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1;
}

