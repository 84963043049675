.new-div {
    font-family: "Arial", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .container_login {
    width: 100%;
    max-width: 400px;
  } 
  
  .login_card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login_head {
    text-align: center;
    color: #333;
  }
  
  .login_form {
    display: flex;
    flex-direction: column;
  }
  
  .login_input {
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    outline: none;
    color: #333;
  }
  
  .login_input:focus {
    border-color: #ff8c00; /* Warna orange saat focus */
  }
  
  .login_button {
    background-color: #ff8c00; /* Warna orange cerah */
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .login_button:hover {
    background-color: #ff7f00; /* Warna orange sedikit lebih gelap saat hover */
  }
  