.container{
  margin-bottom:5%;
}

#form-header{
  margin-top:5%;
  text-align:center;
}
.productdetails_img{
  width: 100%;
  height: auto;
}
#form-tagline{
  background: #ff6d00;
  border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  color:#ffffff;
  margin-top:5%;
  padding: 4%;
  text-align:left;
}

#form-tagline .fa{
  margin-bottom:15%;
}

#form-tagline h2 {
  margin-bottom:15%;
}

#form-content{
  background: #f4f4f4;
  border-top-right-radius: 0.5rem;
    border-bottom-right-radius:0.5rem;
  margin-top:5%;
  padding: 3%;
}
.form-control {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%; 
}
.form-group{
  margin-top:5%;
}

.contact{
    padding: 4%;
    height: 400px;
}

.control-label{
  font-size:16px;
    font-weight:600;
}

div .submit-buttons{
  margin-top:3%;
  text-align:right;
}

.submit{
  white-space: normal;
  width:auto;
  background: #ff6d00 !important;
  color: #ffffff;
  font-weight: 600;
  /* width: 25%; */
}
