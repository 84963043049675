.addselpage{
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .addselpage1{
    width: 100%;
    height: 90%;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .addselpage2{
    width: 310px; 
    height: 295px;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .mtcard{
    margin-top: 25%;
  }
  
  