/* Hero Section */
.servicep1 {
  height: 500px;
  width: 100%;
  background-image: url(/public/assets/img/SERVICE.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.text-box {
  width: 90%;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-box h1 {
  font-size: 62px;
}

/* Card Layout */
.homescard1 {
  margin-top: -50px;
  width: 90%; /* Increased width to accommodate 4 cards */
}

.service_card_top {
  margin-top: 12%;
}

/* Grid Container */
.row.d-flex.justify-content-center {
  display: flex;
  flex-wrap: wrap;
  margin: -10px; /* Negative margin to offset grid item margins */
}

/* Grid Items */
.grid_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(25% - 20px); /* 25% width for 4 items per row, accounting for margins */
  margin: 10px;
  cursor: pointer;
}

/* Responsive Breakpoints */
@media (max-width: 1200px) {
  .grid_item {
    width: calc(33.333% - 20px); /* 3 items per row */
  }
}

@media (max-width: 992px) {
  .grid_item {
    width: calc(50% - 20px); /* 2 items per row */
  }
  .homescard1 {
    width: 95%;
  }
}

@media (max-width: 576px) {
  .grid_item {
    width: calc(100% - 20px); /* 1 item per row */
  }
  .homescard1 {
    width: 100%;
  }
}

/* Card Styling */
.cardhs1 {
  border: none;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;  
  background-color: #d4ccc4 !important;
}

.cardhs1:hover {
  transform: scale(1.05);
}

/* Image Container */
.img-container {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
}

.img1 {
  width: auto;
  height: 80px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

/* Text Styling */
.service-back-title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.card-title h5 {
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 1.2;
  margin: 0;
  padding: 0 5px;
}

/* Utility Classes */
.Logout {
  background-color: #ffa41c !important;
}