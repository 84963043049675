@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto+Condensed:ital,wght@0,400;0,700;1,300;1,400&display=swap');

*{
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Roboto Condensed', sans-serif;
}

.cards{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}
.cardbody{
    border-radius: 15px !important;
}
.card-headers{
    background: transparent !important;
    font-weight:700;
    font-size: 30px;
    font-family: 'Josefin Sans', sans-serif;
    border: none !important;
    text-align: center;
}
.forms{
    margin-top: 10px;
}
.inputs{
    margin: 10px 0 10px 0;
}
.submit-button{
    background-color: #FF7F50 !important;
    border: none !important;
}
.btm-border{
    border-bottom: #FF7F50 3px solid;
    margin-bottom: 20px;
}

.submit{
   float: right;
}

a:link {
    text-decoration: none;
}

/* Main Page CSS Start */

.mainPage {
    width: 100vw;
    height: 100vh;
    /* background-color:#ffa500; */
    display: flex;
    justify-content: center;
    align-items: center; 
}

.btnMainPage {
    background-color: #FF7F50 !important;
    width: 100%;
    font-size: 20px !important;
    font-family: 'Josefin Sans', sans-serif;
    box-shadow: 5px #FF7F50;
    margin-bottom: 15px;
    padding: 10px 10px 10px 10px !important;
    border-radius: 10px !important;
    border-color: #FF7F50 !important;
}
.main-page-icon {
    margin-right: 10px;
}

.mainPage {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .btnMainPage {
      font-size: 1em;
      padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .btnMainPage {
      font-size: 0.8em;
      padding: 6px 12px;
  }
}

/* Main Page CSS End */

.cursor-pointer {
    cursor: pointer;
}

.pl-10 {
    padding-left: 10px;
}

.rating-list li {
    float: right;
    color: #ddd;
    cursor: pointer;
    font-size: 22px;
}

.rating-list li:hover,
.rating-list li:hover ~ li {
    color: #ffd700;
}

.rating-list {
    display: inline-block;
    list-style: none;
}

/* Order tracking css */


figure {
  display: flex;
}
figure img {
  width: 8rem;
  border-radius: 50%;
  border: 1px solid #f05a00;
  margin-right: 1.5rem;
}
figure figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
figure figcaption h4 {
  font-size: 1.4rem;
  font-weight: 500;
}
figure figcaption h6 {
  font-size: 1rem;
  font-weight: 300;
}
figure figcaption h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.order-track {
  padding: 0 1rem;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}
.order-track-step {
  display: flex;
  height: 7rem;
}
.order-track-step:last-child {
  overflow: hidden;
  height: 4rem;
}
.order-track-step:last-child .order-track-status span:last-of-type {
  display: none;
}
.order-track-status {
  margin-right: 1.5rem;
  position: relative;
}
.order-track-status-dot {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: #f05a00;
}
.order-track-status-line {
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 7rem;
  background: #f05a00;
}
.order-track-text-stat {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 3px;
}
.order-track-text-sub {
  font-size: 1rem;
  font-weight: 300;
}

.order-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

th {
  white-space: nowrap;
}

.location-icon {
  font-size: 30px;
}


.rows{
  flex-direction: row;
}