#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #222 !important;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

.sidebar-brand {
  position: absolute;
  top: 0;
  width: 250px;
  text-align: center;
  padding: 20px 0;
}

.sidebar-brand h2 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}

.sidebar-nav {
  position: absolute;
  top: 75px;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav > li {
  text-indent: 10px;
  line-height: 42px;
}

.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: #757575;
  font-weight: 600;
  font-size: 18px;
}

.sidebar-nav > li > a:hover,
.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #fff;
  background: #F8BE12;
}

.sidebar-nav > li > a i.fa {
  font-size: 24px;
  width: 60px;
}
#navbar-wrapper {
  width: 100%;
  position: fixed; 
  z-index: 1030; 
  background-color: #eee;
}

.navbar-brand {
  position: relative;
  z-index: 1040; 
}

#sidebar-wrapper {
  z-index: 1000;
}


#wrapper.toggled #navbar-wrapper {
  margin-right: -250px;
}

#navbar-wrapper .navbar {
  border: none;
  background-color: #eee;
  font-size: 24px;
  margin-bottom: 0;
  border-radius: 0;
}

#navbar-wrapper .navbar a {
  color: #757575;
}

#navbar-wrapper .navbar a:hover {
  color: #F8BE12;
}

#content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 100px;
}

#wrapper.toggled #content-wrapper {
  margin-right: -250px;
}

@media (min-width: 992px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 60px;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 60px;
  }

  #wrapper.toggled #navbar-wrapper {
    margin-right: -190px;
  }

  #wrapper.toggled #content-wrapper {
    margin-right: -190px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    margin-right: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #wrapper {
    padding-left: 60px;
  }

  #sidebar-wrapper {
    width: 60px;
  }

  #wrapper.toggled #navbar-wrapper {
    margin-right: -250px;
  }

  #wrapper.toggled #content-wrapper {
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    margin-right: 250px;
  }
}

@media (max-width: 767px) {
  #wrapper {
    padding-left: 0;
  }

  #sidebar-wrapper {
    left: 0;
    width: 0;
    margin-left: 0;
    margin-top: 50px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }

  #navbar-wrapper {
    position: relative;
    width: 100%;
  }

  #wrapper.toggled #navbar-wrapper {
    margin-right: -250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
    width: 100%;
  }

  #wrapper.toggled #content-wrapper {
    margin-right: -250px;
  }

  #navbar-wrapper .navbar {
    background-color: #eee;
    border: none;
  }

  #navbar-wrapper .navbar a {
    color: #757575;
    font-size: 18px; 
  }

  #navbar-wrapper .navbar a:hover {
    color: #F8BE12;
  }

  .sidebar-nav > li > a:hover {
    text-decoration: none;
    color: #fff;
    background: #F8BE12;
  }

  .sidebar-nav > li.active > a {
    text-decoration: none;
    color: #fff;
    background: transparent;
  }

  .btn-warning {
    /* position: fixed; */
    top: 10px;
    right: 10px;
    z-index: 3; 
  }
  #logout-btn {
    position: fixed; 
    top: 10px; 
    right: 10px; 
    z-index: 1050; 
  }
}
