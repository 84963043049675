/* .footer-bg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: url(/public/assets/img/footer.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0 !important;
  z-index: 1000;
}

.footerdata {
  margin-top: 5%;
  justify-content: center;
  display: flex;
  font-weight: 500;
  background-image: url(/public/assets/img/footer1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.footer-text {
  font-size: 13px;
}

.footerdata p {
  margin-top: 40px;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  line-height: 1.7;
}

.footer-head {
  margin-top: 20px;
  color: white;
} */


.bg-footer {
  background-color: #33383c;
  padding: 50px 0 30px;
}
.footer-heading {
  letter-spacing: 2px;
}

.footer-link a {
  color: #acacac;
  line-height: 40px;
  font-size: 14px;
  transition: all 0.5s;
}

.footer-link a:hover {
  color: white;
}

.contact-info {
  color: #acacac;
  font-size: 14px;
}

.footer-social-icon {
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.twitter {
  background-color: #55acee;
  color: #ffffff;
}

.google {
  background-color: #d6492f;
  color: #ffffff;
}

.apple {
  background-color: #424041;
  color: #ffffff;
}

.footer-alt {
  color: #acacac;
}

.footer-heading {
  position: relative;
  padding-bottom: 12px;
}

.footer-heading:after {
  content: '';
  width: 25px;
  border-bottom: 1px solid #FFF;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #1bbc9b;
}

.goontop{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #acacac;
  margin-bottom: 10px;
}
.goontop a {
  color: black;
  line-height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  text-decoration: none;
}

.goontop a:hover{
  color: #d6492f;
}


@media (max-width: 767.98px) {
  .footer-col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left:10px;
  }
}

@media (min-width: 992px) {
  .footer-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 800px) {
  .footer-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.chatbot-toggle {
  position: fixed;
  bottom: 180px;
  right: 0px;
  z-index: 9999;
  cursor: pointer;
  background-color: #FFCC00;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Base styles for chatbot */
.chatbot-container {
  position: fixed;
  bottom: 180px;
  right: 400px; /* Position it on the right side */
  width: 100px; /* Set the width */
  height: 500px; /* Set the height */
  z-index: 9999;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease; /* Smooth transition when toggled */
}

.chatbot-container.hidden {
  right: -400px;  /* Move off-screen when hidden */
}

.chatbot-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.react-chatbot-kit-chat-container {
  width: 420px !important;
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0 !important;
  width: 350px !important;
}

.react-chatbot-kit-user-chat-message {
  width: 300px;
  word-wrap: break-word;
}
.footerdata {
  background-color: #19b49a;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.footerdata p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.footerdata p strong{
  color: black;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.footerdata p {
  font-size: 16px;
  margin-bottom: 10px;
}

.footerdata p {
  color: white;
  text-decoration: none;
}

.footerdata a:hover {
  text-decoration: underline;
}



.broucher-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.broucher-image img {
  width: 100%;
  max-width: 800px; /* Increased from 500px to 800px */
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.broucher-image img:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}