body {
  overflow-x: hidden;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #f8f9fa;
  padding-top: 60px;
  transition: all 0.3s ease;
  z-index: 1500;
  overflow-y: auto; 
}

.sidebar.collapsed {
  left: -250px; 
}

.sidebar.expanded {
  left: 0;
}

.sidebarWrapper {
  padding: 20px;
}

.sidebar a {
  margin-top: 20px;
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
}

.sidebar a:hover {
  background-color: #ddd;
}

.fixed-toggle {
  top: 60px;
  left: 40px;
  z-index: 1000;
  background: none;
  border: none;
  padding: 0; 
  cursor: pointer;
}

.fixed-toggle:hover,
.fixed-toggle:focus {
  outline: none;
  box-shadow: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: block;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%; 
  }
  .sidebar.collapsed {
    left: -100%;
  }
}
