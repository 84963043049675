/* global css */


body
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 23px;
	font-weight: 400;
	background: #FFFFFF;
	color: #1e1e27;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 1.7;
	font-weight: 500;
	color: #989898;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 2px #fde0db;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FF6347;
	background: #fde0db;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: #fde0db;
	color: #FF6347;
}
p::selection
{
	background: #fde0db;
}
h1{font-size: 72px;}
h2{font-size: 40px;}
h3{font-size: 28px;}
h4{font-size: 24px;}
h5{font-size: 16px;}
h6{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	color: #282828;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection
{

}
::-webkit-input-placeholder
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
:-moz-placeholder /* older Firefox*/
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
::-moz-placeholder /* Firefox 19+ */
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
:-ms-input-placeholder
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
::input-placeholder
{
	font-size: 16px !important;
	font-weight: 500;
	color: #777777 !important;
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}


 


 @import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

:root {
    --font1: 'Heebo', sans-serif;
    --font2: 'Fira Sans Extra Condensed', sans-serif;
    --font3: 'Roboto', sans-serif;

    --btnbg: #ffcc00;
    --btnfontcolor: rgb(61, 61, 61);
    --btnfontcolorhover: rgb(255, 255, 255);
    --btnbghover: #ffc116;
    --btnactivefs: rgb(241, 195, 46);


    --label-index: #960796;
    --danger-index: #5bc257;
/* PAGINATE */
    --link-color: #000;
    --link-color-hover: #fff;
    --bg-content-color: #ffcc00;

}


.card {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    border: 0;
    border-radius: 1rem;
}

.card-img,

.card-img-top {
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px);
}


.card h5 {
    overflow: hidden;
    height: 55px;
    font-weight: 300;
    font-size: 1rem;
}

.card h5 a {
  color: black;
  text-decoration: none;
} 

.card-img-top {
    width: 100%;
    min-height: 250px;
    max-height: 250px;
    object-fit: contain;
    padding: 30px;
}

.card-img-top2 {
    width: 100%;
    min-height: 800px;
    max-height: 800px;
    object-fit: contain;
    padding: 30px;
}

.card h2 {
    font-size: 1rem;
}


.card:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

/* Centered text */
.label-top {
    position: absolute;
    background-color: var(--label-index);
    color: #fff;
    top: 8px;
    right: 8px;
    padding: 5px 10px 5px 10px;
    font-size: .7rem;
    font-weight: 600;
    border-radius: 3px;
    text-transform: uppercase;
}

.top-right {
    position: absolute;
    top: 24px;
    left: 24px;

    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 1rem;
    font-weight: 900;
    background: #8bc34a;
    line-height: 90px;
    text-align: center;
    color: white;
}

.top-right span {
    display: inline-block;
    vertical-align: middle;
    /* line-height: normal; */
    /* padding: 0 25px; */
}

.aff-link {
    /* text-decoration: overline; */
    font-weight: 500;
}

.over-bg {
    background: rgba(53, 53, 53, 0.85);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(0.0px);
    -webkit-backdrop-filter: blur(0.0px);
    border-radius: 10px;
}
.bold-btn {

    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 50px 5px 50px;
}
.box .btn {
    font-size: 1.5rem;
}

@media (max-width: 1025px) {
    .btn {
        padding: 5px 40px 5px 40px;
    }
}
@media (max-width: 250px) {
    .btn {
        padding: 5px 30px 5px 30px;
    }
}

/* START BUTTON */
.btn-warning {
    background: var(--btnbg);
    color: var(--btnfontcolor);
    fill: #ffffff;
    border: none;
    text-decoration: none;
    outline: 0;
    /* box-shadow: -1px 6px 19px rgba(247, 129, 10, 0.25); */
    border-radius: 100px;
}
.btn-warning:hover {
    background: var(--btnbghover);
    color: var(--btnfontcolorhover);
    /* box-shadow: -1px 6px 13px rgba(255, 150, 43, 0.35); */
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
    background: var(--btnbghover);
    color: var(--btnfontcolorhover);
    /* box-shadow: -1px 6px 13px rgba(255, 150, 43, 0.35); */
}
.btn-warning:active:focus {
    box-shadow: 0 0 0 0.25rem var(--btnactivefs);
}
.btn-warning:active {
    background: var(--btnbghover);
    color: var(--btnfontcolorhover);
    /* box-shadow: -1px 6px 13px rgba(255, 150, 43, 0.35); */
}

/* END BUTTON */

.bg-success {
    font-size: 1rem;
    background-color: var(--btnbg)!important;
    color: var(--btnfontcolor)!important;
}
.bg-danger {
    font-size: 1rem;
}


.price-hp {
    font-size: 1rem;
    font-weight: 600;
    color: darkgray;
}

.amz-hp {
    font-size: .7rem;
    font-weight: 600;
    color: darkgray;
}

.fa-question-circle:before {
    /* content: "\f059"; */
    color: darkgray;
}

.fa-heart:before {
    color:crimson;
}
.fa-chevron-circle-right:before {
    color: darkgray;
}



.carousel-item img{
	width: 100%;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: fixed;
  top: 50px; /* Adjust this value to account for the navbar's height */
  left: 0;
  width: 100%;
  z-index: 999;
}
.mainproduct{
	/* position: fixed; */
  /* top: 100px; Adjust this value to account for the navbar's height and any additional space you want */
  left: 50%; /* Position it horizontally */
  transform: translateX(-50%); /* Center it horizontally */
  z-index: 999;
}
@media only screen and (max-width: 600px) {
	.carousel-item img{
		width: 100%;
		height: 300px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
  }

.banner
{  
	margin-bottom: -50px !important;
	width: 100%;
	/* margin-top: -300px; */
}
.banner_item
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* height: 265px; */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.banner_category
{
	height: 50px;
	background: #FFFFFF;
	min-width: 180px;
	padding-left: 25px;
	padding-right: 25px;
}
.banner_category a
{
	display: block;
	color: #1e1e27;
	text-transform: uppercase;
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	line-height: 50px;
	width: 100%;
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-ms-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}
.banner_category a:hover
{
	color: #b5aec4;
}

	
	@media only screen and (max-width: 991px)
{

	h1{font-size: 48px;}
	h2{font-size: 36px;}
	.main_slider_content h6
	{
		margin-bottom: 23px;
	}
	
	
	.main_slider_content
	{
		width: 100%;
		padding-right: 15px;
	}
	.banner_item
	{
		height: 100px !important;
	}
	.banner_category
	{
		min-width: 160px;
		height: 40px;
	}
	.banner_category a
	{
		font-size: 16px;
		line-height: 40px;
	}
	}
	
	

	
	@media only screen and (max-width: 479px)
{
	.main_slider
	{
		/* height: calc(100vh - 70px); */
		height: 80vw;
		/* min-height: auto; */
		/* margin-top: 70px; */
	}
	.main_slider_content h6
	{
		margin-bottom: 15px;
	}
	.main_slider_content h1
	{
		font-size: 24px;
	}
.banner_item
	{
		height: calc((100vw - 30px) / 2.6);
	}
}
.red_button
{
	
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 40px;
	background: #fe4c50;
	border-radius: 3px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.red_button:hover
{
	background: #FE7C7F !important;
}
.red_button a
{
	display: block;
	color: #FFFFFF;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 40px;
	width: 100%;
}


/* .transparent-card{
	background: none !important;
	backdrop-filter: blur(10px) !important;
}	 */


.homeback{
background-color: #E3E6E6 !important;
}


.arrow-up {
    position: fixed;
    right: 10px; /* Adjust this value to position it exactly where you want from the right edge */
    bottom: 20px; /* Adjust this value to position it exactly where you want from the bottom edge */
    z-index: 1000; /* Ensure it's on top of other elements */
}

.arrow-img {
    width: 50px; /* Adjust the size of the image as needed */
    height: auto;
    cursor: pointer;
}
.fa-arrow-up{
	color: black;
	
}
.Qr-scan{
	position: fixed;
    right: 10px; /* Adjust this value to position it exactly where you want from the right edge */
    bottom: 100px; /* Adjust this value to position it exactly where you want from the bottom edge */
    z-index: 1000; /* Ensure it's on top of other elements */
}
.sacn-img {
    width: 50px; /* Adjust the size of the image as needed */
    height: auto;
    cursor: pointer;
}

.chnagepasscard{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10%;
}



