.breadcrumbs
{
	width: 100%;
	height: 60px;
	border-bottom: solid 1px #ebebeb;
	/* margin-bottom: 79px; */
	/* margin-top: 7%; */
}
.breadcrumbs ul li
{
	display: inline-block;
	margin-right: 17px;
}
.breadcrumbs ul li.active a
{
	color: #b9b4c7;
}
.breadcrumbs ul li i
{
	margin-right: 17px;
}
.breadcrumbs ul li a
{
	color: #51545f;
}


.size-select{
	width: 60%;
	background-color: transparent;
	border-radius: 10px;
}

.btn1{
	width: 100%;
	padding: 1rem 2rem  1rem 2rem;
	/* padding-bottom: 0.5rem; */
	border-width: 0;
	border-color: none;
	border-radius: 0.8rem;
	margin-left: 20px;
	text-decoration: none !important;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.star{
	margin-right: 15px !important;
}

.btn-check{
	width: 100%;
	padding: .5rem 2rem  .5rem 2rem;
	/* padding-bottom: 0.5rem; */
	border-width: 0;
	border-color: none;
	border-radius: 0.8rem;
	/* margin-left: 20px; */
	text-decoration: none !important;
}

.pan-image{
	margin: 10px 20px 10px 20px;
	height: 200px;
	width: 300px;
}