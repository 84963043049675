/* .saller-margin{
    margin-top: 10% !important;
} */


@media screen and (max-width: 1500px) {
    .saller-margin{
        margin-top: 15%;
    }
}

.button-product{
    border: 2px solid #ffcc00 !important;
    background-color: #ffcc00 !important;
    margin-right: 5px;
    margin-bottom: 5px;
}

.Allbuttons{
    margin-left: 40%;
}

.modal-header .btn-close{
    background-image: url(/public/assets/img/close.png);
    /* background-size: cover; */
    background-position: center; 
    background-repeat: no-repeat;
}


.stock-component{
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.stock-content ul li{
 margin: 20px 30px 20px 30px !important;
 font-size: 20px;
}

.button-container {
    /* bottom: 40px; */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    z-index: 999; /* Ensure it's above other elements */
}

.service-upload-section{
    bottom: 10px;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.circular-progress circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }



  /* Base styles for pagination panel */
.ag-paging-panel {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 14px;
    background: #fff;
  }
  
  /* Page size selector styles */
  .ag-paging-page-size {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  
  .ag-picker-field {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .ag-label {
    white-space: nowrap;
  }
  
  .ag-wrapper {
    min-width: 60px;
  }
  
  /* Row summary panel styles */
  .ag-paging-row-summary-panel {
    display: flex;
    gap: 4px;
    align-items: center;
    margin: 0 16px;
  }
  
  /* Page summary panel styles */
  .ag-paging-page-summary-panel {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  /* Button styles */
  .ag-paging-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    padding: 4px;
    cursor: pointer;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .ag-paging-panel {
      flex-direction: column;
      gap: 16px;
      width: 100%;
    }
  
    .ag-paging-page-size {
      margin-right: 0;
      width: 100%;
      justify-content: center;
    }
  
    .ag-paging-row-summary-panel {
      margin: 0;
      width: 100%;
      justify-content: center;
    }
  
    .ag-paging-page-summary-panel {
      width: 100%;
      justify-content: center;
    }
    .seller-infocard{
      width: 100% !important;
    }
  }
  
  /* Even smaller screens */
  @media screen and (max-width: 480px) {
    .ag-paging-panel {
      gap: 12px;
      padding: 8px 4px;
    }
  
    .ag-paging-button {
      min-width: 28px;
      height: 28px;
    }
  
    .ag-paging-description {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  
    .ag-picker-field {
      font-size: 12px;
    }
  }
  
  /* Accessibility styles */
  .ag-paging-button:focus {
    outline: 2px solid #0066cc;
    outline-offset: 2px;
  }
  
  .ag-paging-button.ag-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }


  .x-btn {
    top: 5px;  
    right: 5px; 
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Makes the button round */
    z-index: 10; /* Ensure it appears above the image */
    background-color: rgba(255, 0, 0, 0.8); /* Slight transparency */
    border: none;
}
