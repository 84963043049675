  .bg-gradient-primary {
    background: linear-gradient(45deg, #4e54c8, #8f94fb);
  }
  
  .bg-gradient-success {
    background: linear-gradient(45deg, #43a047, #66bb6a);
  }
  
  .bg-gradient-warning {
    background: linear-gradient(45deg, #ff9800, #ffc107);
  }
  
  .bg-gradient-danger {
    background: linear-gradient(45deg, #e53935, #ef5350);
  }
