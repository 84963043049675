.navbar {
  padding: 0.5rem 1rem;
}

.logo-image {
  height: 50px !important; 
  width: 50px; 
  border-radius: 50%; 
  overflow: hidden;
}

.search-container {
  max-width: 800px; 
  width: 100%;
}

.input-group .form-control {
  max-width: 100%;
  border-radius: 50px; 
}

.input-group .btn-warning {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.navbar-nav .nav-item {
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

@media (max-width: 991.98px) {
  .navbar-nav {
    margin-top: 0.5rem;
  }
  
  .navbar-nav .nav-item {
    font-size: 0.8rem;
  }
}

@media (max-width: 767.98px) {
  .navbar-nav .nav-item {
    font-size: 0.75rem;
  }
}

@media (max-width: 575.98px) {
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
  }
}
